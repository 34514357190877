import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import useTranslate from "../../common/useTranslate";

export default function TrackRequestStatusDialog({requests, isOpen, close}: any) {
    const translate = useTranslate();
    const handleClose = (event: any, reason: any) => {
      if (reason && reason === "backdropClick") 
          return;
      close();
    }

    const statusToEmoji = (status:string) => {
      if (status ===  "pending")
        return "⏳";
      if (status ===  "failed")
        return "❌";
      if (status === "done")
        return "✅";
    }

    const done = requests?.filter((req: any) => req.status === "pending").length === 0;
    return (
        <React.Fragment>
          <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Tracking requests"}
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">{translate("security.sharedDrive.add_tracked_drives.wait_for_confirmation")}</Typography>
                <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                >
                    <Box>
                    {
                        requests?.map((req:any) => (
                            <Box
                                key={req.driveId}
                                mt={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography variant="body1">{req.driveId}</Typography>
                                <Typography variant="body1" display="inline" ml="70px">{statusToEmoji(req.status.status)}</Typography>
                                {
                                  req.status.status === "failed" && <Typography variant="body1" display="inline" ml ="20px">{translate(req.status.reason)}</Typography>
                                }
                            </Box>)
                    )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} autoFocus disabled={!done}>
                {translate("generic.OK")}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
}