import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../authentication";
import { useDomainConfig } from "../context";
import useTranslate from "./common/useTranslate";
import ModuleCard from "./common/ModuleCard";

function ModuleCardWithKPIs({ link, title, description, KPIs, disabled }:
    {
        link: string,
        title: string,
        description: string,
        KPIs: string[],
        disabled?: boolean
    }
){
    return <ModuleCard link={link} title={title} disabled={disabled}>
        <Box minHeight="85px">
            <Typography variant="body2">
                {description}
            </Typography>
        </Box>
        <Typography variant="body2">
            {/* {translate("homePage.includedKPIs")} */}
            <ul style={{marginTop: "4px"}}>
                {KPIs.map(KPI => <li key={KPI}>{KPI}</li>)}
            </ul>
        </Typography>
    </ModuleCard>
}

export default function HomePage() {
    const { user } = useAuth();
    const domainConfig = useDomainConfig();
    const translate = useTranslate();

    useEffect(() => {
        document.title = "GSI - Home"
    }, []);
    return <Box height="100%" display="flex" flexDirection="column">
        <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" maxHeight={600}>
            <Box>
                <Typography variant="h5" className="greyBodyText" mb="28px">{translate("homePage.welcomeMessage")}</Typography>
                <Box display="flex" maxWidth="1200px" mt="16px">
                    <ModuleCardWithKPIs
                        title={translate("globalReports.name")}
                        link="/app/global-reports"
                        disabled={!user.isAdmin}
                        description={translate("globalReports.description")}
                        KPIs={[
                            translate("globalReports.KPIs.adoption"),
                            translate("globalReports.KPIs.security"),
                            translate("globalReports.KPIs.support"),
                            translate("globalReports.KPIs.carbon_footprint"),
                        ]}/>
                    
                    <ModuleCardWithKPIs
                            title={translate("security.name")}
                            link="/app/security-and-alerting"
                            disabled={!user.isAdmin || !domainConfig?.modules.security}
                            description={translate("security.description")}
                            KPIs={[
                                translate("security.KPIs.sharedDrive"),
                                translate("security.KPIs.gmail"),
                                translate("security.KPIs.alerts"),
                            ]}
                        />
                    
                    <ModuleCardWithKPIs
                        title={translate("endUser.name")}
                        link="/app/individual-reports"
                        disabled={!domainConfig?.modules.my_gsi}
                        description={translate("endUser.description")}
                        KPIs={[
                            translate("endUser.KPIs.statistics"),
                            translate("endUser.KPIs.comparison"),
                            translate("endUser.KPIS.usage"),
                        ]}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
}