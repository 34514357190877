import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Switch, TextField, Typography } from "@mui/material";
import { AlertConfig, DomainDrive, useClient } from "../../../../api";
import useTranslate, { TranslationKey } from "../../../common/useTranslate";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import AlertEditForm, { ChipListInput, default_sensitive_file_extensions, EditFormContext, FormProps, ReceiversList, TitleSetting } from "../common/AlertEditForm";

type AutocompleteOption = DomainDrive & { label: string }

function SharedDriveSetting() {
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();

    const [pickedOption, setPickedOption] = useState<AutocompleteOption | null>(null);
    const [options, setOptions] = useState<AutocompleteOption[]>();
    const { listDomainDrives }= useClient();

    useEffect(() => {
        listDomainDrives()
            .then(drives => drives)
            .then(drives => drives.map(
                drive => ({...drive, label: `${drive.id} - ${drive.name}`})
            ))
            .then(setOptions);
    }, []);

    const availableOptions = options?.filter(
        drive => config.shared_drive!.driveIds?.findIndex(id => id == drive.id) == -1
    );


    const setDriveIds = (driveIds: string[] | null) => {
        const newConfig = {
            ...config,
            shared_drive: {
                ...config.shared_drive,
                driveIds
            }
        } as Omit<AlertConfig, "id">;
        setConfig(newConfig);
    }

    const handleRadioGroupChange = (event: ChangeEvent) => {
        const value = (event.target as HTMLInputElement).value;
        if (value === "all") {
            setDriveIds(null);
            return;
        } else if (value === "specified") {
            setDriveIds([]);
            return;
        }
    }

    return (<Box mb={3}>
        <FormControl>
            <Typography variant="body1">{translate("security.alerts.sharedDrive.specify_drives")}</Typography>
            <RadioGroup
                onChange={handleRadioGroupChange}
                value={ config.shared_drive?.driveIds ? "specified" : "all" }
            >
                <FormControlLabel value="all" control={<Radio size="small" />} label={translate("security.alerts.sharedDrive.all_drives")} componentsProps={{ typography: { variant: "body2" } }} />
                <FormControlLabel value="specified" control={<Radio size="small" />} label={translate("security.alerts.sharedDrive.following_drives")} componentsProps={{ typography: { variant: "body2" } }} />
                {
                    config.shared_drive?.driveIds && (
                        <Box ml={2} mt={1}>
                            {
                                (config.shared_drive?.driveIds &&
                                    config.shared_drive?.driveIds.map((id: string) => <Box
                                        mb={1}
                                        key={id}
                                    >
                                        <Chip
                                            disabled={requestSubmitted}
                                            size="small"
                                            label={options?.find(option => option.id === id)?.name}
                                            onDelete={() => setDriveIds(config.shared_drive?.driveIds?.filter(r => r !== id) || null)}
                                        />
                                    </Box>)
                                )
                            }
                            <Box display={"flex"} alignItems={"center"}>
                                <Autocomplete
                                    sx={{width:"222px"}}
                                    disablePortal
                                    options={availableOptions || []}
                                    value={pickedOption}
                                    onChange={(_, value) => {if(value)setPickedOption(value)}}
                                    loading={!availableOptions}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        disabled={requestSubmitted}
                                        variant="outlined"
                                        label={translate("security.alerts.sharedDrive.add_drive")}
                                        size="small"
                                    />}
                                />
                                    
                                <Button
                                    sx={{ marginLeft: "8px" }}
                                    variant="outlined"
                                    disabled={!pickedOption}
                                    size="medium"
                                    onClick={() => {
                                        setDriveIds([...(config.shared_drive?.driveIds || []), pickedOption!.id]);
                                        setPickedOption(null);
                                    }}
                                >{translate("generic.add")}</Button>
                            </Box>
                        </Box>)
                    }
            </RadioGroup>
        </FormControl>
    </Box>);
}

function EventSettings() {
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();

    if(!config.shared_drive) {
        console.error("Expected config to have a shared_drive field.");
        return null;
    }

    const events = config.shared_drive.events;

    const changeSharingAlertPreferences = (preferences: {domain_allowlist?: string[], domain_blocklist?: string[]} | null) => {
        const newConfig = {
            ...config,
            shared_drive: {
                ...config.shared_drive,
                events: {
                    ...events,
                    sharing : preferences
                }
            }   
        } as Omit<AlertConfig, "id">;
        setConfig(newConfig);
    }

    const handleRadioGroupChange = (event: ChangeEvent) => {
        const value = (event.target as HTMLInputElement).value;
        if(value === "all") {
            changeSharingAlertPreferences({});
            return;
        }
        if(value === "allowlist") {
            changeSharingAlertPreferences({
                domain_allowlist: []
            });
            return;
        }

        if(value === "blocklist") {
            changeSharingAlertPreferences({
                domain_blocklist: []
            });
            return;
        }
    }

    const setFileExtensions = (fileExtensions: string[]) => {
        const newConfig = {...config};
        events.sensitive_file_extensions = fileExtensions;
        setConfig(newConfig);
    }

    return (
        <FormControl>
            <Typography variant="body1">{translate("security.alerts.sharedDrive.specify_events")}</Typography>
            <FormControlLabel labelPlacement="end" control={
                <Switch
                    onChange={(_, checked) => {
                        if (checked)
                            changeSharingAlertPreferences({});
                        else
                            changeSharingAlertPreferences(null);
                    }}
                    checked={Boolean(events.sharing)}
                />
            } label={translate("security.alerts.sharedDrive.sharing")} />
            {
                Boolean(events.sharing) &&
                <Box ml={3}>
                    <RadioGroup
                        name="radio-buttons-group"
                        onChange={handleRadioGroupChange}
                        value={events.sharing?.domain_allowlist ? "allowlist" : (events.sharing?.domain_blocklist ? "blocklist" : "all")}
                    >
                        <FormControlLabel value="all" control={<Radio size="small" />} label={translate("security.alerts.sharedDrive.all_new_users")} componentsProps={{ typography: { variant: "body2" } }} />
                        <FormControlLabel value="allowlist" control={<Radio size="small" />} label={translate("security.alerts.sharedDrive.allowlist")} componentsProps={{ typography: { variant: "body2" } }} />
                        {
                            events.sharing?.domain_allowlist && <Box ml={2} mb={2}>
                                <ChipListInput
                                    label={translate("security.alerts.generic.add_domain")}
                                    values={events.sharing.domain_allowlist}
                                    setValues={(val) => changeSharingAlertPreferences({domain_allowlist: val})}
                                />
                            </Box>
                        }
                        <FormControlLabel value="blocklist" control={<Radio size="small" />} label={translate("security.alerts.sharedDrive.blocklist")} componentsProps={{ typography: { variant: "body2" } }} />
                        {
                            events.sharing?.domain_blocklist && <Box ml={2} mb={2}>
                                <ChipListInput
                                    label={translate("security.alerts.generic.add_domain")}
                                    values={events.sharing.domain_blocklist}
                                    setValues={(val) => changeSharingAlertPreferences({domain_blocklist: val})}
                                />
                            </Box>
                        }
                    </RadioGroup>
                </Box>
            }

            <FormControlLabel disabled={requestSubmitted} labelPlacement="end" control={
                <Switch onChange={(_, checked) => {
                    setFileExtensions(checked ? default_sensitive_file_extensions : []);
                }} checked={events.sensitive_file_extensions.length > 0} />
            } label={translate("security.alerts.sharedDrive.sensitive_file_types")} />
            { events.sensitive_file_extensions.length > 0 && <Box ml={2}>
                <ChipListInput
                    values={events.sensitive_file_extensions}
                    setValues={val => setFileExtensions(val)}
                    label={translate("security.alerts.sharedDrive.add_file_type")}
                />
            </Box> }
            <Typography variant="body2"></Typography>
        </FormControl>
    );
}


export default function SharedDriveAlertEditForm({config, setConfig, submit}: FormProps) {
    return <AlertEditForm config={config} setConfig={setConfig} submit={submit}>
        <Box display="flex">
            <Box flex={1} mr={1}>
                <TitleSetting />
                <SharedDriveSetting />
                <ReceiversList />
            </Box>
            <Box flex={1} ml={1}>
                <EventSettings  />
            </Box>
        </Box>
    </AlertEditForm>
}