import { Box, Typography } from "@mui/material";
import { Navigate, Outlet, Route } from "react-router-dom";
import useTranslate from "../../common/useTranslate";
import SharedDriveAlertsAccordion from "./shared-drive/SharedDriveAlerts";
import NewSharedDriveAlertPage from "./shared-drive/NewSharedDriveAlert";
import ManageAlertPage from "./ManageAlert";
import { useEffect } from "react";
import { GmailAlertsAccordion } from "./gmail/GmailAlerts";
import NewGmailAlertPage from "./gmail/NewGmailAlert";

function AlertsHome() {
    const translate = useTranslate();

    useEffect(() => {
            document.title = `GSI - ${translate("security.alerts.name")}`
    }, []);

    return (
        <Box padding={4}>
            <Typography variant="h5" mb="16px" className="greyBodyText">
                {translate("security.alerts.name")}
            </Typography>
            <Typography variant="body2">{translate("security.alerts.welcome")}</Typography>

            <Box mt={2}> <SharedDriveAlertsAccordion /> </Box>
            <Box mt={2}> <GmailAlertsAccordion /> </Box>
        </Box>
    );
}
export default (path: string) => (
    <Route path = {path} element={<Outlet/>}>
        <Route index element={<AlertsHome/>} />
        <Route path="create-shared-drive" element={<NewSharedDriveAlertPage/>} />
        <Route path="create-gmail" element={<NewGmailAlertPage/>} />
        <Route path=":configId" element={<ManageAlertPage/>} />
        <Route path="*" element={<Navigate to="."/>}></Route>
    </Route>
)