import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from "@mui/material/Button";
import { DialogTitle } from "@mui/material";
import { TrackedDrive, useClient } from "../../../api";
import useTranslate from "../../common/useTranslate";

export default function StopTrackingDriveDialog({drive, isOpen, close}: {drive: TrackedDrive | undefined, isOpen: boolean, close: any}) {
    const { stopTrackingDrive } = useClient();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    if (!drive)
        return null;


    const handleSubmit = () => {
        setLoading(true);
        stopTrackingDrive(drive.id)
            .then(() => close(true))
            .finally(() => setLoading(false))
    };

    return (
      <React.Fragment>
        <Dialog
          open={isOpen}
          onClose={() => close(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${translate("security.sharedDrive.stop_tracking")} ${drive?.name}?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate("security.sharedDrive.stop_tracking.notice")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} disabled={loading} autoFocus color="error">
              {translate("security.sharedDrive.stop_tracking")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  