import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from '../authentication';
import RouterBreadcrumbs from "./Breadcrumbs"
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDomainConfig } from '../context';

function DomainLogo(
) {
  const domainConfig = useDomainConfig();

  if(!domainConfig || !domainConfig.logo_url) return null;

  return <img
    src={domainConfig.logo_url}
    alt={domainConfig.domain}
    height="45px"
    style={{marginRight: "16px"}}
  />
}

function TopBar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <DomainLogo />
          <RouterBreadcrumbs />
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
          </Box>
          {
            auth.loggedIn && <Box display="flex" alignItems="center">
              <Box mr="16px">
                <Typography sx={{ fontSize: 'medium', textAlign: 'end' }}>{auth.user.email}</Typography>
                {/* <Typography sx={{ fontSize: 'small', textAlign: 'end' }}>{auth.user.domain}</Typography> */}
              </Box>
              <IconButton color="inherit" onClick={auth.logout}><LogoutIcon /></IconButton>
            </Box>
          }
          <Link
            to='mailto:gsi-support@revevol.com'
            target="_blank"
            style={{color:'inherit', padding: "8px", height:"24px"}}
          ><HelpIcon /></Link>
          <IconButton
            onClick={() => navigate(0)}
            style={{color:'inherit'}}
          >
            <RefreshIcon />
          </IconButton>
          {location.pathname !== "/app/login" && <IconButton
            onClick={() => navigate(-1)}
            style={{color:'inherit'}}
          >
            <ArrowBackIcon />
          </IconButton>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
