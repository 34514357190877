import { Box, LinearProgress, Typography } from "@mui/material"
import { Route } from "react-router-dom"
import useTranslate from "../../common/useTranslate"
import EmbeddedLookerReport from "../../common/EmbeddedLookerReport";
import { useDomainConfig } from "../../../context";

function GmailSecurityPage() {
    const translate = useTranslate();
    const domainConfig = useDomainConfig();

    return(
        <Box padding="32px 32px 0px 32px" boxSizing="border-box" height="100%" display="flex" flexDirection="column">
            <Box display = "flex">
                <Typography variant="h5">{translate("security.gmail.name")}</Typography>
            </Box>
            {
                !domainConfig
                    ? <LinearProgress />
                    : <Box flex="1">
                        <EmbeddedLookerReport reportPath={domainConfig!.modules.security!.gmail.report_url}/>
                    </Box>
            }
        </Box>
    )
}

export default (path: string) => (
    <Route path={path} element={<GmailSecurityPage />}/>
)