import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertConfig, useClient } from "../../../api";
import { Box, LinearProgress, Typography } from "@mui/material";
import useTranslate from "../../common/useTranslate";
import SharedDriveAlertEditForm from "./shared-drive/SharedDriveAlertEditForm";
import GmailAlertEditForm from "./gmail/GmailAlertEditForm";

export default function ManageAlertPage() {
    const [config, setConfig] = useState<AlertConfig>();
    const [loading, setLoading] = useState(false);
    const { configId } = useParams();
    const { getAlertConfig, updateAlertConfig } = useClient();
    const translate = useTranslate();

    useEffect(() => {
        document.title = `GSI - ${translate("security.alerts.sharedDrive.manage_alert")}`
    }, []);

    useEffect(() => {
        setLoading(true);
        if(configId) {
            getAlertConfig(configId)
                .then(setConfig)
                .then(() => setLoading(false))
        }
    }, [configId]);

    if(loading || !config)
        return <LinearProgress />;

    const handleSubmit = async() => {
        await updateAlertConfig(config);
    }

    const updateConfig = (newConfig: Omit<AlertConfig, "id">) => {
        setConfig({
            id: config.id,
            ...newConfig
        })
    }

    return (<Box padding={4}>
        <Typography variant="h5" mb="16px" className="greyBodyText">
            {translate("security.alerts.sharedDrive.manage") + ` "${config.title}"`}
        </Typography>
        {
            config.type === "SharedDrive"
                ? <SharedDriveAlertEditForm config={config} setConfig={updateConfig} submit={handleSubmit}/>
                : <GmailAlertEditForm config={config} setConfig={updateConfig} submit={handleSubmit}/>
        }
    </Box>);
}