import { createContext, useContext, useEffect, useState } from "react";
import { AlertConfig, useClient } from "../../../../api";
import { Box, CircularProgress, Grid, IconButton, LinearProgress, Menu, MenuItem, Typography } from "@mui/material";
import useTranslate from "../../../common/useTranslate";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export function MoreButton({config}: {config: AlertConfig}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { deleteAlertConfig } = useClient();
    const translate = useTranslate();
    const { refreshList } = useContext(AlertTableContext);
    const [deleting, setDeleting] = useState(false);
    const navigate = useNavigate();

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        handleClose();
        setDeleting(true);
        await deleteAlertConfig(config.id)
        await refreshList();
        setDeleting(false);
    }

    const handleManage = () => {
        navigate(`${config.id}`);
        handleClose();
    }

    return (<Box display="flex" justifyItems="end">
        {
            deleting
                ? <CircularProgress size="24px" sx={{margin: "4px"}}/>
                : <IconButton
                    onClick={handleButtonClick}
                ><MoreVertIcon /></IconButton>
        }
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={handleDelete}>{translate("security.alerts.sharedDrive.delete_alert")}</MenuItem>
            <MenuItem onClick={handleManage}>{translate("security.alerts.sharedDrive.manage_alert")}</MenuItem>
        </Menu>
    </Box>)
}


export function RowText({text}: {text: string}) {
    return <Typography variant="body2" overflow="hidden" mr="4px">{text}</Typography>
}

export const AlertTableContext = createContext<{refreshList: () => Promise<void>}>({
    refreshList: async () => console.error("Context has not set refreshList."),
});

type AlertsTableProps = {
    alertType: AlertConfig["type"],
    columns: { label: string, width: number}[],
    rowDisplay: (config: AlertConfig) => JSX.Element,
}

export default function AlertsTable({
    alertType,
    columns,
    rowDisplay,
}: AlertsTableProps) {
    const [alertConfigs, setAlertConfigs] = useState<AlertConfig[]>();
    const { listAlertConfigs } = useClient();
    
    const refreshList = () => {
        return listAlertConfigs()
            .then(configs => configs.filter(config => config.type === alertType))
            .then(setAlertConfigs);  
    }

    useEffect(() => {
        refreshList();
    }, []);

    const headerText = (text: string) => (
        <Typography fontWeight="bold" variant="body2">{text}</Typography>
    );

    return(
        <AlertTableContext.Provider value={{refreshList}}>
            <Box padding={1}>
            {/* Header */}
            <Grid container margin={"0px 8px"}>
                {
                    columns.map(col => <Grid xs={col.width}>{headerText(col.label)}</Grid>)
                }
            </Grid>
            {alertConfigs
                ? alertConfigs?.map(config => rowDisplay(config))
                : <LinearProgress />}
        </Box>
        </AlertTableContext.Provider>
    );
}
