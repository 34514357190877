import React, { useContext, useEffect } from "react";
import { TrackedDrive, useClient } from "../../../api";
import { useParams } from "react-router-dom";

export function useCurrentTrackedDrive() {
    const [currentTrackedDrive, setCurrentTrackedDrive] = React.useState<TrackedDrive>();
    const { getTrackedDrive } = useClient();
    const { sharedDriveId } = useParams();
    useEffect(() => {
        setCurrentTrackedDrive(undefined);
        if(sharedDriveId)
            getTrackedDrive(sharedDriveId).then(setCurrentTrackedDrive);
    }, [sharedDriveId]);

    return currentTrackedDrive;
}