import { useEffect, useState } from "react";
import { AlertConfig, useClient } from "../../../api";
import { useParams } from "react-router-dom";

export function useCurrentAlertConfig() {
    const [currentAlertConfig, setCurrentAlertConfig] = useState<AlertConfig>();
    const { getAlertConfig } = useClient();
    const { configId } = useParams();
    useEffect(() => {
        setCurrentAlertConfig(undefined);
        if(configId)
            getAlertConfig(configId).then(setCurrentAlertConfig);
    }, [configId]);

    return currentAlertConfig;
}