import axios from 'axios';
import { useAuth } from './authentication';
import { z } from 'zod';

export class Report {
    constructor(
        public name: string,
        public domain: string,
        public title: string,
        public admin_only: boolean,
        public id: string,
        public category:string,
        public icon: string,
        public index?: number,
        public description?: string
    ) { }
    static parse({ name, domain, title, admin_only, id, category, icon, index, description }:
        { name: string, domain: string, title: string, admin_only: boolean, id: string, category: string, icon: string, index?: number, description?: string }) {
        return new Report(name, domain, title, admin_only, id, category, icon, index, description);
    }
}

export class User {
    constructor(
        public email: string,
        public domain: string,
        public isAdmin: boolean,
    ) { }

    static parse({ email, domain, is_admin }: { email: string, domain: string, is_admin: boolean }) {
        return new User(email, domain, is_admin);
    }
}

export class TrackedDrive {
    constructor(
        public id: string,
        public user: string,
        public domain: string,
        public trackingRequestTimestamp: string,
        public status: string,
        public fileCount: string,
        public name: string,
        public backgroundImageLink: string
    ) {}

    static parse({id, user, domain, trackingRequestTimestamp, status, fileCount, name, backgroundImageLink }: any) {
        return new TrackedDrive(id,user, domain, trackingRequestTimestamp, status, fileCount, name, backgroundImageLink);
    }
}

export class DomainDrive {
    constructor(
        public id: string,
        public createdTime: string,
        public fileCount: string,
        public name: string,
        public backgroundImageLink: string
    ) {}

    static parse({id, fileCount, name, backgroundImageLink, createdTime}: any) {
        return new DomainDrive(id, createdTime, fileCount, name, backgroundImageLink);
    }
}

const DomainConfigSchema = z.object({
    domain: z.string(),
    language: z.string(),
    secrets: z.object({
        delegatedsa: z.string(),
        domainadmin: z.string(),
        gsiscannersa: z.string(),
    }),
    tables: z.object({
        directory: z.object({
            accounts: z.string(),
            groups: z.string(),
            group_members: z.string(),
        }),
        drive: z.object({
            shared_drives: z.string(),
            shared_drive_permissions: z.string(),
            shared_drive_files: z.string(),
            shared_drive_file_permissions: z.string(),
        }),
        activity_logs: z.string(),
    }),
    modules: z.object({
        security: z.object({
            shared_drive: z.object({
                report_url_template: z.string(),
                limited_to_drives: z.array(z.string()).nullish(),
            }),
            gmail: z.object({
                report_url: z.string(),
            })
        }).nullish(),
        my_gsi: z.object({}).nullish(),
    }),
    logo_url: z.string().nullish(),
});
export type DomainConfig = z.infer<typeof DomainConfigSchema>;

const AlertConfigSchema = z.object({
    id: z.string(),
    title: z.string(),
    type: z.union([z.literal("SharedDrive"), z.literal("Gmail")]),
    receivers: z.array(z.string()),
    shared_drive:
        z.object({
            driveIds: z.nullable(z.array(z.string())),
            events: z.object({
                sensitive_file_extensions: z.array(z.string()),
                sharing: z.object({
                    domain_allowlist: z.array(z.string()).nullish(),
                    domain_blocklist: z.array(z.string()).nullish(),
                }).nullish()
            })
        }).nullish(),
    gmail: z.object({
        domain_allowlist: z.array(z.string()).nullish(),
        domain_blocklist: z.array(z.string()).nullish(),
        events: z.object({
            sensitive_attachment_extensions: z.array(z.string()),
            forwarding_rule_added: z.boolean()
        })
    }).nullish(),
});
export type AlertConfig = z.infer<typeof AlertConfigSchema>

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST || window.location.origin;

export function useClient() {
    const { logout } = useAuth();

    return  {
        getReport: wrap(getReport, logout),
        listReports: wrap(listReports, logout),
        getUser: wrap(getUser, logout),
        listTrackedDrives: wrap(listTrackedDrives, logout),
        getTrackedDrive: wrap(getTrackedDrive, logOut),
        listDomainDrives: wrap(listDomainDrives, logout),
        getDomainDrive: wrap(getDomainDrive, logout),
        startTrackingDrive: wrap(startTrackingDrive, logout),
        stopTrackingDrive: wrap(stopTrackingDrive, logout),
        getDomainConfig: wrap(getDomainConfig, logout),
        listAlertConfigs: wrap(listAlertConfigs, logout),
        getAlertConfig: wrap(getAlertConfig, logout),
        createAlertConfig: wrap(createAlertConfig, logout),
        deleteAlertConfig: wrap(deleteAlertConfig, logout),
        updateAlertConfig: wrap(updateAlertConfig, logout),
    }
}
const cache = {} as any;

function wrap<T extends Function>(func: T, logoutFunction: Function) {
    const wrapper = async (...args: any[]) => {
        try {
            return await func(...args);
        } catch (e: any) {
            if(e.response?.status && (e.response.status === 401)) {
                console.log("Not logged in.")
                logoutFunction();
                throw e;
            }
            throw e;
        }
    }

    return wrapper as unknown as T;
}

async function getReport(reportName: string, invalidateCache=false){
    const cacheKey = "getReport:" + reportName;
    if (!invalidateCache) {
        if (cache[cacheKey])
            return cache[cacheKey] as Report;
    }

    const res = await axios.get(BACKEND_HOST + "/api/get-report/" + reportName, {withCredentials: true});

    const result = Report.parse(res.data);
    cache[cacheKey] = result;
    return result;
}

async function listReports(invalidateCache=false) {
    const cacheKey = "listReports";
    if (!invalidateCache) {
        if (cache[cacheKey])
            return cache[cacheKey];
    }

    const res = await axios.get(BACKEND_HOST + "/api/list-reports", {withCredentials: true});

    const result = res.data.map((item: any) => Report.parse(item));
    cache[cacheKey] = result;
    result.forEach((element: any) => { //also updates the getReport cache.
        const key = "getReport:" + element["name"];
        cache[key] = element;
    });
    return result;
}

async function getUser() {
    const res = await axios.get(BACKEND_HOST + "/api/get-user", {withCredentials:true});
    const result =  User.parse(res.data);
    return result;
}

async function listTrackedDrivesPaginated(cursor?:string): Promise<[TrackedDrive[], string]> {
    const res = await axios.get(BACKEND_HOST + "/api/list-tracked-drives", {
        params: {cursor : cursor},
        withCredentials: true
    });
    const result = res.data.tracked_drives.map((drive:any) => TrackedDrive.parse(drive));
    return [result, res.data.cursor];
}

async function listTrackedDrives(): Promise<TrackedDrive[]> {
    const drives = []
    let cursor: string | undefined;
    do {
        const [drivesInPage, nextCursor] = await listTrackedDrivesPaginated(cursor);
        drives.push(...drivesInPage)
        cursor = nextCursor;
    } while (cursor);

    return drives;
}

async function getTrackedDrive(driveId: string) {
    // TODO we might want to actually implement this endpoint later.
    return listTrackedDrives()
        .then(drives => drives.find(drive => drive.id === driveId))
}

async function getDomainConfig(): Promise<DomainConfig> {
    return axios.get(BACKEND_HOST + "/api/get-domain-config", {
        withCredentials: true
    }).then(res => DomainConfigSchema.parse(res.data));
}


async function listDomainDrives(invalidateCache=false): Promise<DomainDrive[]> {
    const cacheKey = "domainDrives";
    if (!invalidateCache) {
        if (cache[cacheKey])
            return cache[cacheKey];
    }

    const res = await axios.get(BACKEND_HOST + "/api/list-drives-in-domain", {
        withCredentials: true
    });
    const result = res.data.map((drive:any) => DomainDrive.parse(drive));

    cache[cacheKey] = result;

    return result;
}

async function getDomainDrive(id: string): Promise<DomainDrive | undefined> {
    const drives = await listDomainDrives();
    return drives.find(drive => drive.id === id);
}

async function startTrackingDrive(driveId: string): Promise<any> {
    const res = await axios.post(BACKEND_HOST + "/api/start-tracking-drive", {
        driveId
    }, {withCredentials : true});
    return res.data;
}

async function stopTrackingDrive(driveId: string): Promise<any> {
    const res = await axios.post(BACKEND_HOST + "/api/stop-tracking-drive", {
        driveId
    }, {withCredentials: true});
    return res.data;
}


async function listAlertConfigs(): Promise<AlertConfig[]> {
    const res = await axios.get(BACKEND_HOST + "/api/list-alert-configs", {
        withCredentials: true
    });
    return res.data.configs.map((config: any) => AlertConfigSchema.parse(config));
}

async function getAlertConfig(configId: string) {
    // TODO we might want to actually implement this endpoint later.
    return listAlertConfigs()
        .then(configs => configs.find(config => config.id === configId))
}

async function createAlertConfig(config: Omit<AlertConfig, "id">): Promise<void> {
    await axios.post(BACKEND_HOST + "/api/create-alert-config", 
        config,
        { withCredentials: true }
    );
}

async function deleteAlertConfig(configId: string): Promise<void> {
    await axios.post(BACKEND_HOST + "/api/delete-alert-config", 
        { configId },
        { withCredentials: true }
    );
}

async function updateAlertConfig(config: AlertConfig): Promise<void> {
    await axios.post(BACKEND_HOST + "/api/update-alert-config", 
        config,
        { withCredentials: true }
    );
}

export async function logIn(idToken: string) {
    const res = await axios.get(BACKEND_HOST + "/api/log-in", {
        withCredentials: true,
        headers: {
            "Authorization": `Bearer ${idToken}`
        }
    });
    const result =  User.parse(res.data);
    return result;
}

export async function logOut() {
    await axios.get(BACKEND_HOST + "/api/log-out", {withCredentials: true});
    return;
}