import { useNavigate } from "react-router-dom";
import useTranslate from "./useTranslate";
import { Box, Card, CardActionArea, CardContent, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function ModuleCard(
    {link, title, children, disabled}: {
        link: string,
        title: string,
        children: ReactNode,
        disabled?: boolean
    }
) {
    const navigate = useNavigate();
    const translate = useTranslate();

    return (
        <Tooltip placement="top" title={disabled && <div style={{whiteSpace: "pre-line"}}>
            {translate("auth.module_disabled")}
            </div>}
        >
            <Card sx={{borderRadius:"8px", width: 350,  margin: "8px", display: "flex"}}> 
            <CardActionArea
                onClick={() => navigate(link)}
                sx={{
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "column",
                    ...(disabled && {opacity: 0.38, pointerEvents: "none"})
                }}
            >
                <CardContent sx={{flexGrow: "1", padding: "16px 24px 16px 16px", width:"100%", boxSizing: "border-box"}}>
                    <Typography variant="h6" className="darkblueHeader" fontWeight="bold" mb={1}>
                        {title}
                    </Typography>
                    <Box className="greyBodyText">
                        {children}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Tooltip>
    );
}