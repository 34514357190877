import { useEffect } from "react";
import { useCurrentTrackedDrive } from "./currentTrackedDrive";
import * as React from 'react';
import Box from '@mui/material/Box';
import { LinearProgress, Typography } from "@mui/material";
import EmbeddedLookerReport from "../../common/EmbeddedLookerReport";
import { useClient } from "../../../api";
import { Outlet, useOutletContext } from "react-router-dom";

type OutletContext = {reportPath: string, driveId: string};

export function TrackedDriveReport() {
  const { reportPath } = useOutletContext<OutletContext>();
  return <EmbeddedLookerReport reportPath={reportPath}/>
}

export default function TrackedDrivePage() {
    const drive = useCurrentTrackedDrive();
    const { getDomainConfig } = useClient();
    const [urlTemplate, setUrlTemplate] = React.useState<string>();

    useEffect(()=> {console.log(drive)});

    useEffect(() => {
      getDomainConfig().then(config => setUrlTemplate(config.modules.security?.shared_drive.report_url_template));
    }, []);

    useEffect(() => {
        document.title = `GSI - ${drive?.name}`
    }, [drive])

    if (!drive || !urlTemplate)
        return <LinearProgress />;

    const reportPath = urlTemplate.replaceAll("[__SHARED_DRIVE_ID__]", drive.id);

    return (
        <Box padding="32px 32px 0px 32px" boxSizing="border-box" height="100%" display="flex" flexDirection="column">
            <Box display = "flex">
                <Typography variant="h5">{drive.name}</Typography>
            </Box>
            <Box flex="1">
                <Outlet context={{driveId: drive.id, reportPath} satisfies OutletContext}/>
            </Box>
        </Box>
    );
}
