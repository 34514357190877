import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { DomainDrive, TrackedDrive, useClient } from '../../../api';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import useTranslate from '../../common/useTranslate';


function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function EnhancedTableHead(props: any) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, awaitingResponse} =
    props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  const translate = useTranslate();

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: translate("generic.drive_id"),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: translate("generic.name"),
    },
    {
      id: 'fileCount',
      numeric: true,
      disablePadding: false,
      label: translate("generic.num_files")
    },
    {
      id: 'createdTime',
      numeric: true,
      disablePadding: false,
      label: translate("generic.creation_time"),
    }
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={awaitingResponse}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  awaitingResponse: PropTypes.bool.isRequired,
};

function EnhancedTableToolbar(props: any) {
  const { numSelected, onSubmit, awaitingResponse, loadingDrives, searchString, setSearchString } = props;
  const waiting = awaitingResponse || loadingDrives;
  const translate = useTranslate();
  return (<Box>
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {translate("generic.selected")}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {translate("security.sharedDrive.add_tracked_drives.select_drives")}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title={translate("generic.submit")}>
          <Button variant="outlined" disabled={waiting} onClick={onSubmit}>{translate("generic.submit")}</Button>
        </Tooltip>
      ) : <TextField
        size="small"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        label={translate("security.sharedDrive.add_tracked_drives.search_by_name")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        />}
    </Toolbar>
    {waiting && <Box marginTop="5px"><LinearProgress hidden={!waiting}/></Box>}
    </Box>
  );
}

export default function EnhancedTable({idToken, sendRequest, displayAlert}: any) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [awaitingResponse, setAwaitingResponse] = React.useState(false);
  const [loadingDrives, setLoadingDrives] = React.useState(true);
  const [drives, setDrives] = React.useState<DomainDrive[]>([]);
  const [searchString, setSearchString] = React.useState("");

  const { listDomainDrives, listTrackedDrives} = useClient();
  const translate = useTranslate();

  React.useEffect(() => {
    let domainDrives: DomainDrive[];
    let trackedDrives: TrackedDrive[];

    const promise1 = listDomainDrives()
          .then(response => domainDrives = response)
          .catch((error) => {
            if(error.response && error.response.status >= 400 && error.response.status < 500)
              displayAlert(error.response.data);
            else
              displayAlert(translate("generic.unexpected_error"));
            })

    const promise2 = listTrackedDrives()
          .then(response => trackedDrives = response);

    Promise.all([promise1, promise2])
      .then(() => {
        const untrackedDrives = domainDrives.filter(drive => trackedDrives.findIndex(tracked => tracked.id === drive.id) === -1);
        setDrives(untrackedDrives);
      })
      .then(() => setLoadingDrives(false));
  }, [idToken])

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if(awaitingResponse) return;
    if (event.target.checked && drives) {
      const newSelected = drives.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, id: any) => {
    if(awaitingResponse) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - drives.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        drives.filter(drive => drive.name.toLowerCase().includes(searchString.toLowerCase())),
        getComparator(order, orderBy)
      ).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, drives, drives.length, searchString],
  );

  async function onSubmit() {
    setAwaitingResponse(true);
    await sendRequest(selected).then(() => setAwaitingResponse(false));
}

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSubmit={onSubmit}
          awaitingResponse={awaitingResponse}
          loadingDrives={loadingDrives}
          searchString={searchString}
          setSearchString={setSearchString}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={drives.length}
              awaitingResponse={awaitingResponse}
            />
            <TableBody>
              {
              visibleRows.map((row: any, index: any) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={awaitingResponse}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.fileCount}</TableCell>
                    <TableCell align="right">{new Date(row.createdTime).toDateString()}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (33) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          lang='fr'
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={drives.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage={translate("security.sharedDrive.add_tracked_drives.rows_per_page")}
          labelDisplayedRows={({ from, to, count })  => {
              return `${from}–${to} ${translate("security.sharedDrive.add_tracked_drives.table_of")} ${count !== -1 ? count : `${translate("security.sharedDrive.add_tracked_drives.table_more_than")} ${to}`}`;
            }}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}