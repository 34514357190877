import { Box, Divider, Grid } from "@mui/material";
import useTranslate from "../../../common/useTranslate";
import { useEffect, useState } from "react";
import { AlertConfig, DomainDrive, useClient } from "../../../../api";
import { Link } from "react-router-dom";
import AlertsAccordion from "../common/AlertsAccordion";
import AlertsTable, { MoreButton, RowText } from "../common/AlertsTable";

function DriveIdRepresentation({driveId} : {driveId: string}) {
    const [domainDrive, setDomainDrive] = useState<DomainDrive>();
    const { getDomainDrive } = useClient();
    
    useEffect(() => {
        getDomainDrive(driveId).then(setDomainDrive)
    }, [driveId]);


    const label = domainDrive ? domainDrive.name : driveId;
    return (
        <Box><Link to={`https://drive.google.com/drive/folders/${driveId}`} target="_blank">{label}</Link></Box>
    )
}

function SharedDriveAlertRow({config}: {config: AlertConfig}) {
    const translate = useTranslate();
    if(!config.shared_drive) {
        console.error("Expected alert to have a shared_drive field.");
        return;
    }

    const driveIdsRepresentation = config.shared_drive.driveIds
        ? <>{config.shared_drive.driveIds.map((id) =><DriveIdRepresentation driveId={id} />)}</>
        : <RowText text={translate("security.alerts.sharedDrive.allSharedDrives")} />

    const computeEventsRepresentation = () => {
        const lines: string[] = [];

        const events = config.shared_drive!.events;

        if(events.sensitive_file_extensions.length > 0)
            lines.push(translate("security.alerts.sharedDrive.sensitiveFileTypes"));

        if(events.sharing)
            lines.push(translate("security.alerts.sharedDrive.sharing"))

        return <>{lines.map(line => <RowText text={line}/>)}</>;
    }
    const eventsRepresentation = computeEventsRepresentation();

    const receiversRepresentation = <>{config.receivers.map(r => <RowText text={r}/>)}</>

    return (<>
        <Divider sx={{margin: "8px 0px"}}/>
        <Grid container margin={"0px 8px"}>
            <Grid xs={2}>{<RowText text={config.title} />}</Grid>
            <Grid xs={3}>{driveIdsRepresentation}</Grid>
            <Grid xs={3}>{eventsRepresentation}</Grid>
            <Grid xs={3}>{receiversRepresentation}</Grid>
            <Grid xs={1}><MoreButton config={config}/></Grid>
        </Grid>
    </>);
}

function SharedDriveAlertsTable() {
    const translate = useTranslate();
   
    return (
        <AlertsTable
            alertType="SharedDrive"
            columns={[
                { width: 2, label: translate("security.alerts.generic.column.title") },
                { width: 3, label: translate("security.alerts.sharedDrive.column.shared_drives") },
                { width: 3, label: translate("security.alerts.generic.column.alert_on") },
                { width: 3, label: translate("security.alerts.generic.column.receivers") },
            ]}
            rowDisplay={config => <SharedDriveAlertRow config={config}/>}
        />
    );
}

export default function SharedDriveAlertsAccordion() {
    const translate = useTranslate();

    return (<AlertsAccordion
        label={translate("security.alerts.sharedDrive.name")}
        iconName="shared_drive"
        addAlertPath="create-shared-drive"
    ><SharedDriveAlertsTable /></AlertsAccordion>);
}