import { useAuth } from "./authentication";
import SimpleMessagePage from "./components/common/SimpleMessagePage";
import useTranslate from "./components/common/useTranslate";
import { useDomainConfig } from "./context";

export function RequireAdmin({children}: { children: JSX.Element }) {
    const auth = useAuth();
    const translate = useTranslate();

    if(!auth.user.isAdmin)
        return <SimpleMessagePage message={translate("auth.requires_admin")}/>
    
    return children;
}

export function RequireSecurityModule({children}: {children: JSX.Element}) {
    const domainConfig = useDomainConfig();
    const translate = useTranslate();
    if(!domainConfig?.modules.security)
        return <SimpleMessagePage message={translate("auth.security_disabled")}/>

    return children;
}

export function RequireMyGSIModule({children}: {children: JSX.Element}) {
    const domainConfig = useDomainConfig();
    const translate = useTranslate();
    if(!domainConfig?.modules.my_gsi)
        return <SimpleMessagePage message={translate("auth.MyGSI_disabled")}/>

    return children;
}