import { createContext, ReactNode, useContext, useState } from "react";
import { AlertConfig } from "../../../../api"
import useTranslate from "../../../common/useTranslate";
import { useNavigate } from "react-router-dom";
import { Box, Button, Chip, CircularProgress, FormControl, TextField, Typography } from "@mui/material";

export type FormProps = {
    config: Omit<AlertConfig, "id">,
    setConfig: (value: Omit<AlertConfig, "id">) => void,
    submit: () => Promise<void>
}

type EditFormContextType = {
    config: Omit<AlertConfig, "id">,
    setConfig: (value: Omit<AlertConfig, "id">) => void,
    requestSubmitted: boolean,
}

export const EditFormContext = createContext<EditFormContextType>({
    config: null as unknown as AlertConfig,
    setConfig: () => console.error("Context has not set setConfig."),
    requestSubmitted: false
});

// taken from https://support.google.com/mail/answer/6590#zippy=%2Cmessages-that-have-attachments
export const default_sensitive_file_extensions = [
    "ade", "adp", "apk", "appx", "appxbundle", "bat", "cab", "chm", "cmd", "com", "cpl", "diagcab",
    "diagcfg", "diagpkg", "dll", "dmg", "ex", "ex_", "exe", "hta", "img", "ins", "iso", "isp", "jar",
    "jnlp", "js", "jse", "lib", "lnk", "mde", "mjs", "msc", "msi", "msix", "msixbundle", "msp", "mst",
    "nsh", "pif", "ps1", "scr", "sct", "shb", "sys", "vb", "vbe", "vbs", "vhd", "vxd", "wsc", "wsf",
    "wsh", "xll",
]

export function TitleSetting() {
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();
    const setTitle = (title: string) => {
        const newConfig = {
            ...config,
            title  
        } as Omit<AlertConfig, "id">;
        setConfig(newConfig);
    }

    return(<Box mb={3}>
        <FormControl>
            <Typography variant="body1" mb={1}>{translate("security.alerts.generic.column.title")}</Typography>
            <TextField
                disabled={requestSubmitted}
                variant="outlined"
                size="small"
                value={config.title}
                onChange={e => setTitle(e.target.value)}
            />
        </FormControl>
    </Box>);
}

export function ChipListInput({values, setValues, label}: {
    values: string[],
    setValues: (val: string[]) => void,
    label: string
}) {
    const { requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();

    const [currentValue, setCurrentValue] = useState("");
    return (<Box mt={1}>
            {
                values.toSorted().map((extension: string) => <Box
                    display={"inline"}
                    mr={1}
                    mb={1}
                    mt={1}
                    key={extension}
                >
                    <Chip
                        disabled={requestSubmitted}
                        size="small"
                        label={extension}
                        onDelete={() => setValues(values.filter(r => r !== extension))}
                    />
                </Box>)
            }
            <Box mt={1} display={"flex"} alignItems={"center"}>
                <TextField
                    disabled={requestSubmitted}
                    variant="outlined"
                    label={label}
                    size="small"
                    value={currentValue}
                    onChange={e => setCurrentValue(e.target.value)}
                    onKeyDown={(ev: any) => {
                        if (ev.key === 'Enter') {
                            setValues([...values, currentValue]);
                            setCurrentValue("");
                        }
                    }}
                />
                <Button
                    sx={{ marginLeft: "8px" }}
                    variant="outlined"
                    disabled={currentValue.length === 0}
                    size="medium"
                    onClick={() => {
                        setValues([...values, currentValue]);
                        setCurrentValue("");
                    }}
                >{translate("generic.add")}</Button>
            </Box>
        </Box>
    );
}

export function ReceiversList() {
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();

    const [receiver, setReceiver] = useState("");

    const setReceivers = (receivers: string[]) => {
        const newConfig = { ...config }
        newConfig.receivers = receivers;
        setConfig(newConfig)
    }

    const receiverIsValid = receiver.length > 0 && /^[\w.%+-]+@[\w.-]+\.[\w]{2,}$/.test(receiver);

    return (<FormControl>
        <Typography variant="body1">{translate("security.alerts.generic.specify_receivers")}</Typography>
        <Box ml={2} mt={1}>
            {
                config.receivers.map((receiver: string) => <Box
                    mb={1}
                    key={receiver}
                >
                    <Chip
                        disabled={requestSubmitted}
                        size="small"
                        label={receiver}
                        onDelete={() => setReceivers(config.receivers.filter(r => r !== receiver))}
                    />
                </Box>)
            }
            <Box display={"flex"} alignItems={"center"}>
                <TextField
                    disabled={requestSubmitted}
                    variant="outlined"
                    label={translate("security.alerts.sharedDrive.add_receiver")}
                    size="small"
                    value={receiver}
                    onChange={e => setReceiver(e.target.value)}
                    error={receiver.length > 0 && !receiverIsValid}
                    onKeyDown={(ev: any) => {
                        if (ev.key === 'Enter') {
                            setReceivers([...config.receivers, receiver]);
                            setReceiver("");
                        }
                    }}
                />
                <Button
                    sx={{ marginLeft: "8px" }}
                    variant="outlined"
                    disabled={!receiverIsValid}
                    size="medium"
                    onClick={() => {
                        setReceivers([...config.receivers, receiver]);
                        setReceiver("");
                    }}
                >{translate("generic.add")}</Button>
            </Box>
        </Box>
    </FormControl>);
}


export default function AlertEditForm({config, setConfig, submit, children}: FormProps & {children: ReactNode}) {
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const translate = useTranslate();
    const navigate = useNavigate();

    const contextValue: EditFormContextType = {
        config,
        setConfig,
        requestSubmitted,
    };

    const saveChanges = async () => {
        setRequestSubmitted(true);
        await submit();
        setRequestSubmitted(false);
        navigate("..");
    };

    return <Box>
        <EditFormContext.Provider value = {contextValue}>
            {children}
        </EditFormContext.Provider>
        <Box display="flex" justifyContent="end" margin={1}>
            <Button variant="outlined" onClick={saveChanges}>{requestSubmitted ? <CircularProgress size="24px"/> : translate("generic.save_changes")}</Button>
        </Box>
    </Box>
}