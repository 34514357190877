import { Box, Typography } from "@mui/material";
import { AlertConfig, useClient } from "../../../../api";
import useTranslate from "../../../common/useTranslate";
import { useEffect, useState } from "react";
import GmailAlertEditForm from "./GmailAlertEditForm";

const defaultAlertConfig = {
    title: "New Gmail alert",
    type: "Gmail",
    receivers: [],
    gmail: {
        events: {
            forwarding_rule_added: true,
            sensitive_attachment_extensions: [],
        },
    }
} as Omit<AlertConfig, "id">;


export default function NewGmailAlert() {
    const [config, setConfig] = useState(defaultAlertConfig);
    const translate = useTranslate();
    const { createAlertConfig } = useClient();

    useEffect(() => {
        document.title = `GSI - ${translate("security.alerts.gmail.new_gmail_alert")}`
    }, []);

    const handleSubmit = async () => {
        await createAlertConfig(config);
    }

    return <Box padding={4}>
        <Typography variant="h5" mb="16px" className="greyBodyText">
            {translate("security.alerts.gmail.create")}
        </Typography>
        <GmailAlertEditForm config={config} setConfig={setConfig} submit={handleSubmit} />
    </Box>
}