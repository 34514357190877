import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AuthProvider, LoginPage, RequireAuthentication, useAuth } from '../authentication';
import { CurrentReportProvider, DomainConfigProvider } from '../context';
import HomePage from './HomePage';
import { Box, Toolbar } from '@mui/material';
import TopBar from './TopBar';
import DrawerMenu from './Drawer';
import GlobalReports from './reports/GlobalReports';
import UserReports from './reports/UserReports';
import SecurityAndAlerting from './security-and-alerting/SecurityAndAlerting';

function App() {
  return (
    <AuthProvider>
    <DomainConfigProvider>
    <CurrentReportProvider>
      <div className="App" style={{ height: "100vh" }}>
        <Routes>
          <Route path="/app" element={<Layout />}>
            <Route index element={<RequireAuthentication><HomePage /></RequireAuthentication>}></Route>
            <Route path="/app/login" element={<LoginPage />}></Route>
            {GlobalReports}
            {UserReports}
            {SecurityAndAlerting("security-and-alerting")}
            <Route
              path="/app/*"
              element={<Navigate to="/app" replace={true} />}
            />
          </Route>
        </Routes>
      </div>
    </CurrentReportProvider>
    </DomainConfigProvider>
    </AuthProvider>
  );
}

function Layout() {
  const {loggedIn} = useAuth();
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <TopBar />
      {loggedIn && <DrawerMenu />}
      <Toolbar />
      <Box display={'flex'} flexDirection="row" flexGrow="1">
        {loggedIn && <Box minWidth="300px" />}
        <Box flexGrow="1" height="100%">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default App;
