import { Typography, TextField, Button, Box, Paper, LinearProgress, Divider } from "@mui/material";
import DrivesTable from "./DrivesTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./AlertDialog";
import { useClient } from "../../../api";
import TrackRequestStatusDialog from "./TrackRequestStatusDialog";
import useTranslate from "../../common/useTranslate";

function TextInput({sendRequest}: any) {
    const [waiting, setWaiting] = useState(false);
    const translate = useTranslate();

    async function onSubmit() {
        setWaiting(true);
        const textField = (document.getElementById("driveIds") as any).value
        const driveIds = textField.split(',').map((s: string) => s.trim());
        await sendRequest(driveIds);
        setWaiting(false);
    }

    return <Box sx={{ width: '100%', marginTop: "16px"}}>
      <Paper sx={{ mb: 2, padding: "16px"}}>
    <Typography variant="h6" marginBottom="16px">{translate("security.sharedDrive.add_tracked_drives.specify_ids")}</Typography>
    <Box display="flex" alignItems="center">
        <TextField
        id="driveIds"
        label={translate("security.sharedDrive.add_tracked_drives.drive_ids_comma_separated")}
        multiline
        fullWidth
        disabled={waiting}
        />
        <Box marginLeft="10px">
            <Button disabled={waiting} variant="outlined" onClick={onSubmit}>{translate("generic.submit")}</Button>
        </Box>
    </Box>
    {
        waiting && <Box marginTop="5px"><LinearProgress hidden={!waiting}/></Box>
    }
    </Paper>
</Box>
}

export function AddTrackedDrives () {
    const navigate = useNavigate();
    const translate = useTranslate();
    const { startTrackingDrive } = useClient();

    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [alertDialogText, setAlertDialogText] = useState("");

    const [requestsDialogOpen, setRequestsDialogOpen] = useState(false);
    const [ouststandingRequests, setOutstandingRequests] = useState<any>();
    async function displayAlert(text: string) {
            setAlertDialogText(text);
            setAlertDialogOpen(true);
    }

    async function sendRequest(driveIds: string[]) {
        const requests = {} as {[key: string]: {
            status: string,
            reason?: string
        }};
        setRequestsDialogOpen(true);

        const updateStatuses = () => setOutstandingRequests(Object.entries(requests).map((([k,v]) => ({"driveId": k, "status": v}))));

        driveIds.forEach(id => {
            requests[id] = {status: "pending"};
            startTrackingDrive(id)
                .then(() => requests[id] = {status: "done"})
                .catch((e) => { requests[id] = {status: "failed", reason: e.response.data};})
                .finally(updateStatuses);
        })
        updateStatuses();        
    }

    useEffect(() => {
        document.title = `GSI - ${translate("security.sharedDrive.add_tracked_drives.title")}`;
    }, []);

    return <Box padding={4}>
        <Typography variant="h5" mb="16px" className="greyBodyText">
            {translate("security.sharedDrive.name")}
        </Typography>
        <Typography variant = "body1" gutterBottom>{translate("security.sharedDrive.add_tracked_drives.drive_limit")} {translate("security.sharedDrive.add_tracked_drives.file_limit")}</Typography>
        <AlertDialog isOpen={alertDialogOpen} close={() => setAlertDialogOpen(false)} text={alertDialogText}/>
        <TrackRequestStatusDialog isOpen={requestsDialogOpen} close={() => {
            setRequestsDialogOpen(false);
            navigate("..");
        }} requests={ouststandingRequests} />
        <DrivesTable sendRequest={sendRequest} displayAlert={displayAlert}/>
        <Divider sx={{marginTop: "20px"}}>{translate("generic.OR")}</Divider>
        <TextInput sendRequest={sendRequest}/>
    </Box>
}