import { ChangeEvent, useContext, useState } from "react";
import AlertEditForm, { ChipListInput, default_sensitive_file_extensions, EditFormContext, FormProps, ReceiversList, TitleSetting } from "../common/AlertEditForm";
import useTranslate from "../../../common/useTranslate";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import { string } from "zod";

function EventSettings () {
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);
    const translate = useTranslate();
    
    const setForwardingAlertOn = (flag: boolean) => {
        const newConfig = { ...config };
        newConfig.gmail!.events.forwarding_rule_added = flag;
        setConfig(newConfig);
    }

    const setSensitiveAttachmentExtensions = (extensions: string[]) => {
        const newConfig = { ...config };
        newConfig.gmail!.events.sensitive_attachment_extensions = extensions;
        setConfig(newConfig);
    }

    const events = config.gmail!.events;

    return (<FormControl>
        <Typography variant="body1">{translate("security.alerts.gmail.specify_events")}</Typography>
        <FormControlLabel
            labelPlacement="end"
            control={<Switch
                onChange={(_, checked) => {setForwardingAlertOn(checked)}}
                checked={events.forwarding_rule_added}
            />}
            label={translate("security.alerts.gmail.forwarding_rule_added")}
            componentsProps={{ typography: { variant: "body2" } }}
        />

        <FormControlLabel
            disabled={requestSubmitted}
            labelPlacement="end"
            control={<Switch
                onChange={(_, checked) => {setSensitiveAttachmentExtensions(checked ? default_sensitive_file_extensions : []);}}
                checked={config.gmail!.events.sensitive_attachment_extensions.length > 0}
            />}
            label={translate("security.alerts.gmail.sensitive_attachment_types")}
            componentsProps={{ typography: { variant: "body2" } }}
        />
        { config.gmail!.events.sensitive_attachment_extensions.length > 0 && <Box ml={2}>
            <ChipListInput
                values={config.gmail!.events.sensitive_attachment_extensions}
                setValues={val => setSensitiveAttachmentExtensions(val)}
                label={translate("security.alerts.sharedDrive.add_file_type")}
            />
        </Box> }
    </FormControl>);
}

function DomainSettings() {
    const translate = useTranslate();
    const { config, setConfig, requestSubmitted } = useContext(EditFormContext);

    const setDomainSetting = (allowlist: string[] | null, blocklist: string[] | null) => {
        const newConfig = { ...config };
        newConfig.gmail!.domain_allowlist = allowlist;
        newConfig.gmail!.domain_blocklist = blocklist;
        setConfig(newConfig);
    }

    const handleRadioGroupChange = (event: ChangeEvent) => {
        const value = (event.target as HTMLInputElement).value;
        if (value === "all") {
            setDomainSetting(null, null);
        } else if (value === "allowlist") {
            setDomainSetting([], null);
        } else {
            setDomainSetting(null, []);
        }
    }

    const radioValue = (() => {
        if (config.gmail?.domain_allowlist)
            return "allowlist";
        if(config.gmail?.domain_blocklist)
            return "blocklist";
        return "all";
    })();

    return <FormControl>
        <Typography variant="body1">{translate("security.alerts.gmail.specify_domains")}</Typography>
        <Box ml={2}>
            <RadioGroup
                onChange={handleRadioGroupChange}
                value={radioValue}
            >
                <FormControlLabel
                    value="all"
                    control={<Radio size="small" />}
                    label={translate("security.alerts.gmail.all_external_domains")}
                    componentsProps={{ typography: { variant: "body2" } }}
                />
                <FormControlLabel
                    value="allowlist"
                    control={<Radio size="small" />}
                    label={translate("security.alerts.gmail.domain_allowlist")}
                    componentsProps={{ typography: { variant: "body2" } }}
                />
                {
                    radioValue === "allowlist" && <Box ml={2}><ChipListInput
                        label={translate("security.alerts.generic.add_domain")}
                        values={config.gmail!.domain_allowlist!}
                        setValues={(val) => setDomainSetting(val, null)}
                    /></Box>
                }
                <FormControlLabel
                    value="blocklist"
                    control={<Radio size="small" />}
                    label={translate("security.alerts.gmail.domain_blocklist")}
                    componentsProps={{ typography: { variant: "body2" } }}
                />
                {
                    radioValue === "blocklist" && <Box ml={2}><ChipListInput
                        label={translate("security.alerts.generic.add_domain")}
                        values={config.gmail!.domain_blocklist!}
                        setValues={(val) => setDomainSetting(null, val)}
                    /></Box>
                }
                
            </RadioGroup>
        </Box>
    </FormControl>
}

export default function GmailAlertEditForm({config, setConfig, submit}: FormProps) {
    return <AlertEditForm config={config} setConfig={setConfig} submit={submit}>
        <Box display="flex" mb={2}>
            <Box flex={1} mr={1}>
                <TitleSetting />
                <EventSettings />
            </Box>
            <Box flex={1} ml={1}>
                <DomainSettings /> 
                <Box mt={2}> <ReceiversList /> </Box>
            </Box>
        </Box>
    </AlertEditForm>
}