import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";
import { Icon } from "../../../common/Icon";
import useTranslate from "../../../common/useTranslate";

type AlertsAccordionProps = {
    label: string,
    iconName: string,
    addAlertPath: string,
    children: ReactNode
}

export default function AlertsAccordion({
    label,
    iconName,
    addAlertPath,
    children
}: AlertsAccordionProps) {
    const navigate = useNavigate();
    const translate = useTranslate();
    const addButtonClicked = (e: any) => {
        e.preventDefault();
        navigate(addAlertPath);
    }
    return (
        <Accordion elevation={2}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <Box display="flex" alignItems="center">
                        <Icon height={30} name={iconName}/>
                        <Typography 
                            variant="body1"
                            sx={{marginLeft: "8px"}}
                        >{label}</Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={addButtonClicked}
                        sx={{marginRight: "16px"}}
                    >{translate("security.alerts.create")}</Button>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}