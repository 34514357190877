import { Box, Typography } from "@mui/material";
import { AlertConfig, useClient } from "../../../../api";
import useTranslate from "../../../common/useTranslate";
import { useEffect, useState } from "react";
import SharedDriveAlertEditForm from "./SharedDriveAlertEditForm";

const defaultAlertConfig = {
    title: "New shared drive alert",
    type: "SharedDrive",
    receivers: [],
    shared_drive: {
        driveIds: null,
        events: {
            sensitive_file_extensions: [],
            sharing_outside_domain: false,
            sharing_outside_drive: false,
            sharing_with_specified_domains: false,
            specified_domains: null
        },
    }
} as Omit<AlertConfig, "id">;


export default function NewSharedDriveAlert() {
    const [config, setConfig] = useState(defaultAlertConfig);
    const translate = useTranslate();
    const { createAlertConfig } = useClient();

    useEffect(() => {
        document.title = `GSI - ${translate("security.alerts.sharedDrive.new_shared_drive")}`
    }, []);

    const handleSubmit = async () => {
        await createAlertConfig(config);
    }

    return <Box padding={4}>
        <Typography variant="h5" mb="16px" className="greyBodyText">
            {translate("security.alerts.sharedDrive.create")}
        </Typography>
        <SharedDriveAlertEditForm config={config} setConfig={setConfig} submit={handleSubmit} />
    </Box>
}