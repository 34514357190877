import { Navigate, Outlet, Route } from "react-router-dom";
import { RequireAuthentication } from "../../authentication";
import { RequireAdmin, RequireSecurityModule
 } from "../../authorization";
import SharedDriveSecurity from "./sd-security/SharedDriveSecurity";
import { Box, Typography } from "@mui/material";
import Alerts from "./alerts/Alerts";
import { useEffect } from "react";
import useTranslate from "../common/useTranslate";
import ModuleCard from "../common/ModuleCard";
import GmailSecurity from "./gmail/GmailSecurity";

function ModuleWrapper() {
    return(<RequireAuthentication>
        <RequireAdmin>
            <RequireSecurityModule>
                <Outlet />
            </RequireSecurityModule>
        </RequireAdmin>
    </RequireAuthentication>);
}

function SecurityModuleIndex() {
    const translate = useTranslate();
    
    useEffect(() => {
        document.title = `GSI - ${translate("security.name")}`
    }, []);

    return (
        <Box height="100%" display="flex" flexDirection="column">
            
            <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center" maxHeight={600}>
                <Box>
                    <Typography variant="h5" className="greyBodyText">{translate("security.name")}</Typography>
                    <Box display="flex" maxWidth="1200px" mt="16px" minHeight="200px">
                    <ModuleCard
                        title={translate("security.sharedDrive.name")}
                        disabled={false}
                        link="shared-drives"
                    >
                        <Typography variant="body2">{translate("security.sharedDrive.welcome")}</Typography>
                    </ModuleCard>
                    <ModuleCard
                        title={translate("security.gmail.name")}
                        disabled={false}
                        link="gmail"
                    >
                        <Typography variant="body2">{translate("security.gmail.welcome")}</Typography>
                    </ModuleCard>
                    <ModuleCard
                        title={translate("security.alerts.name")}
                        disabled={false}
                        link="alerts"
                    >
                        <Typography variant="body2">{translate("security.alerts.welcome")}</Typography>
                    </ModuleCard>
                </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default (path: string) => (
    <Route path={path} element = {<ModuleWrapper />}>
        <Route index element={<SecurityModuleIndex />}/>
        { SharedDriveSecurity("shared-drives") }
        { Alerts("alerts") }
        { GmailSecurity("gmail") }
        <Route path="*" element={<Navigate to="." replace={true} />} />
    </Route>
)