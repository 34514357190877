import { Box, Divider, Grid } from "@mui/material";
import useTranslate from "../../../common/useTranslate";
import AlertsAccordion from "../common/AlertsAccordion";
import AlertsTable, { MoreButton, RowText } from "../common/AlertsTable";
import { AlertConfig } from "../../../../api";

function GmailAlertRow({config}: {config: AlertConfig}) {
    const translate = useTranslate();

    if(!config.gmail) {
        console.error("Expected alert to have a gmail field.");
        return;
    }

    const computeEventsRepresentation = () => {
        const lines: string[] = [];
        const events = config.gmail!.events;

        if(events.forwarding_rule_added)
            lines.push(translate("security.alerts.gmail.forwarding_rule_added"))
        if(events.sensitive_attachment_extensions.length > 0)
            lines.push(translate("security.alerts.gmail.sensitive_attachment"))

        return <>{lines.map(line => <RowText text={line}/>)}</>
    }
    const eventsRepresentation = computeEventsRepresentation();

    const computeDomainsRepresentation = () => {
        if (config.gmail?.domain_allowlist) {
            return (<>
                <RowText text={translate("security.alerts.gmail.any_domain_except")}></RowText>
                {config.gmail.domain_allowlist.map(domain => <RowText key={domain} text={domain}/>)}
            </>)
        }

        if (config.gmail?.domain_blocklist) {
            return (<>
                {config.gmail.domain_blocklist.map(domain => <RowText key={domain} text={domain}/>)}
            </>)
        }

        return <RowText text={translate("security.alerts.gmail.any_domain")}/>
    }

    const receivingDomainsRepresentation = computeDomainsRepresentation();
    const receiversRepresentation = <>{config.receivers.map(r => <RowText text={r}/>)}</>

    return (<>
        <Divider sx={{margin: "8px 0px"}}/>
        <Grid container margin={"0px 8px"}>
            <Grid xs={2}>{<RowText text={config.title} />}</Grid>
            <Grid xs={3}>{receivingDomainsRepresentation}</Grid>
            <Grid xs={3}>{eventsRepresentation}</Grid>
            <Grid xs={3}>{receiversRepresentation}</Grid>
            <Grid xs={1}><MoreButton config={config}/></Grid>
        </Grid>
    </>);
}

function GmailAlertsTable() {
    const translate = useTranslate();

    return (
        <AlertsTable
            alertType="Gmail"
            columns={[
                { width: 2, label: translate("security.alerts.generic.column.title") },
                { width: 3, label: translate("security.alerts.gmail.column.receiving_domains") },
                { width: 3, label: translate("security.alerts.generic.column.alert_on") },
                { width: 3, label: translate("security.alerts.generic.column.receivers") },
            ]}
            rowDisplay={config => <GmailAlertRow config={config}/>}
        />
    );
}

export function GmailAlertsAccordion() {
    const translate = useTranslate();
    
    return (<AlertsAccordion
        label={translate("security.alerts.gmail.name")}
        iconName="gmail"
        addAlertPath="create-gmail"
    ><GmailAlertsTable /></AlertsAccordion>);
}